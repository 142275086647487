const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "select", _style:'min-width:50px' },
    { key: 'jenis_hewan', label: "Jenis Hewan", _style:'min-width:150px;' },
    { key: 'karkas', label: "Karkas (KG)", _style:'min-width:150px;' },
    { key: 'offal', label: "Offal (KG)", _style:'min-width:150px'},
    { key: 'karkas_offal', label: "Karkas + Offal (KG)", _style:'min-width:150px;' },
    { key: 'persentase_offal', label: "Presentase Offal", _style:'min-width:150px;' },
    { key: 'persentase_lemak', label: "Presentase Lemak", _style:'min-width:150px' },
    { key: 'konversi_karkas_ke_daging', label: "Konv. Karkas ke Daging", _style:'min-width:200px' },
    { key: 'unreg_pemotongan_ternak', label: "Unreg Pemotongan Ternak", _style:'min-width:200px' },
    { key: 'pemotongan_terhadap_populasi',label: "Pemotongan Terhadap Populasi", _style:'min-width:200px;' },

    { key: 'dibuat_oleh',label: "Diinput Oleh", _style:'min-width:150px;' },
    { key: 'tanggal_input',label: "Tanggal Update", _style:'min-width:150px;' },
    { key: 'tanggal_update',label: "Tanggal Update", _style:'min-width:150px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:150px'}
]


export { fields }