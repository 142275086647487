<template>
    <div>
      <CCard>
        <CCardBody>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <button
                  class="btn btn-sm btn-primary ml-2"
                  v-if="user.role.name.toLowerCase() == 'admin'"
                  @click="addModal()"
                >
                  <CIcon name="cilMedicalCross" />
                  Tambah
                </button>
  
                <button
                  class="btn btn-sm btn-primary ml-2"
                  v-if="user.role.name.toLowerCase() == 'admin'"
                  @click="addModalImport()"
                >
                  <CIcon name="cilArrowThickToBottom" />
                  Import
                </button>
              
                <!-- <button
                    class="btn btn-sm btn-success mr-2 ml-2"
                  >
                    <CIcon name="cil-spreadsheet" />
                    Export Excel
                </button>
                <button class="btn btn-sm btn-danger">
                  <CIcon name="cib-adobe-acrobat-reader" />
                  Export PDF
                </button> -->
               
                <label class="m-1 ml-3" for="">Select All : </label>
                <input
                  type="checkbox"
                  v-model="isSelectedAll"
                  @change="checkAll()"
                />
  
                <select v-if="selectedItems.length > 0"
                    style="max-width: 200px"
                    class="form-control form-control-sm mx-2"
                    placeholder="Ketik disini"
                    v-model="selectedAction"
                    @change="changeActionSelected()"
                  >
                  <option value="0">Action Selected</option>
                  <option value="1">Delete Items Selected</option>
                  <option value="2">Export Excel Items Selected</option>
                  <option value="3">Export Pdf Items Selected</option>
                </select>
  
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-10">
              <div class="row mb-3">
                <label class="m-1 ml-3" for="">Search : </label>
                <input
                  type="text"
                  v-model="params.keyword"
                  style="max-width: 175px"
                  class="form-control form-control-sm mx-2"
                  placeholder="Ketik disini"
                />
                <button @click="search()" class="btn btn-sm btn-success">
                  Cari
                </button>
  
                <!-- <select
                    style="max-width: 150px"
                    class="form-control form-control-sm mx-1"
                    placeholder="Ketik disini"
                    v-model="params.kecamatan"
                    @change="getData"
                  >
                  <option value="">Kecamatan</option>
                  <option v-for="(item) in kecamatan" :key="item.key" :value="item.value">{{ item.key }}</option>
                </select>
                <select
                    style="max-width: 150px"
                    class="form-control form-control-sm mx-1"
                    placeholder="Ketik disini"
                    v-model="params.desa"
                    @change="getData"
                  >
                  <option value="" >Desa</option>
                  <option v-for="(item) in desa" :key="item.key" :value="item.value">{{ item.key }}</option>
                </select>
                <select
                    style="max-width: 150px"
                    class="form-control form-control-sm mx-1"
                    placeholder="Ketik disini"
                    v-model="params.jenisHewan"
                    @change="getData"
                  >
                  <option value="" >Jenis Hewan</option>
                  <option v-for="(item) in jenisHewan" :key="item.key" :value="item.id">{{ item.nama }}</option>
                </select> -->
              </div>
            </div>
            <div class="col-2">
              <div class="row">
                <div class="col-12">
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01"
                        >Per Halaman</label
                      >
                    </div>
                    <select
                      class="custom-select"
                      style="max-width: 100px"
                      id="inputGroupSelect01"
                      v-model="params.row"
                      @change="getData()"
                    >
                      <!-- <option selected>Pilih...</option> -->
                      <option selected value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="1500">1500</option>
                      <option value="2000">2000</option>
                      <option :value="total">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="isSearching">
            <div class="col">
              <h3>
                <span class="badge bg-primary text-light text-bor my-auto">
                  {{searchOn}}&nbsp;&nbsp;
                  <span @click="searchOff" class="badge bg-light text-dark text-center" style="cursor: pointer">X</span>
                </span>
              </h3>
            </div>
          </div>
          <!-- start table -->
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            sorter
          >
          <template #select="{ item }">
            <td class="py-2">
              <input 
                  type="checkbox"
                  @change="check(item)"
                  v-model="item.select"
                />
            </td>
          </template>
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  @click="edit(item)"
                  class="mr-2"
                  color="warning"
                  square
                  size="sm"
                >
                  Edit
                </CButton>
                <CButton @click="hapus(item)" color="danger" square size="sm">
                  Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="500"
            @paginate="pagination"
          />
        </CCardBody>
      </CCard>
      <!-- Start modal -->
      <CModal
        size="lg"
        :title="isUpdate ? 'Edit Parameter' : 'Tambah Parameter'"
        centered
        :color="isUpdate ? 'success' : 'primary'"
        :show.sync="createModal"
      >
      <div>
        <div class="row">
          <div class="col-4">Jenis Hewan</div>
          <div class="col-4">
            <select
              style="max-width: 100%"
              class="form-control form-control-md mb-3"
              placeholder="Ketik disini"
              v-model="selectedJenisHewanForm"
            >
              <option value="" disable>Jenis Hewan</option>
              <option v-for="(item) in jenisHewan" :key="item.key" :value="{ id: item.id, nama: item.nama }">{{ item.nama }}</option>
            </select>
          </div>
          <div class="col-4"></div>
          <div class="col-4">Karkas (KG)</div>
          <div class="col-8">
            <CInput v-model="form.karkas" placeholder="Karkas (KG)"/>
          </div>
          <div class="col-4">Offal (KG)</div>
          <div class="col-8">
            <CInput v-model="form.offal" placeholder="Offal (KG)"/>
          </div>
          <div class="col-4">Persentase Offal</div>
          <div class="col-8">
            <CInput v-model="form.persentase_offal" placeholder="Pesentase Offal"/>
          </div>
          <div class="col-4">Persentase Lemak</div>
          <div class="col-8">
            <CInput v-model="form.persentase_lemak" placeholder="Persentase Lemak"/>
          </div>
          <div class="col-4">Konv. Karkas ke Daging</div>
          <div class="col-8">
            <CInput v-model="form.konversi_karkas_ke_daging" placeholder="Konv. Karkas ke Daging"/>
          </div>
          <div class="col-4">Unreg Pemotongan Ternak</div>
          <div class="col-8">
            <CInput v-model="form.unreg_pemotongan_ternak" placeholder="Unreg Pemotongan Ternak"/>
          </div>
          <div class="col-4">Pemotongan Terhadap Populasi</div>
          <div class="col-8">
            <CInput v-model="form.pemotongan_terhadap_populasi" placeholder="Pemotongan Terhadap Populasi"/>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="cancel" class="btn btn-secondary mr-3">Batal</button>

          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update 
          </button>
        </div>
      </template>
      </CModal>
      <!-- Import modal -->
      <CModal
          size="lg"
          title="Import Data"
          centered
          color="primary"
          :show.sync="createModalImport"
        >
        <div class="row">
          <div class="col">
            <a href="parameter.xlsx" download class="btn btn-primary mb-3">Download format</a>
            <div class="div">
              <input
                type="file"
                class="form-control"
                ref="importData"
                @change="importData"
              />
            </div>
          </div>
        </div>
        <template slot="footer">
        <div>
          <button @click="cancelImport" class="btn btn-secondary mr-3">Batal</button>

          <button @click="submitImport" class="btn btn-primary">
            Import
          </button>
        </div>
      </template>
      </CModal>
    </div>
  </template>

<script>
  import * as data from "../../model/parameter-produksi";
  import { uploadImage } from "@/utils/fileUpload";
  import kecamatan, { desa } from "@/utils/internalData";
  import FileSaver from "file-saver";


  export default {
    data() {
      return {
        desa,
        kecamatan,
        createModal: false,
        createModalImport: false,
        modalFields: [],
        fields: [],
        isUpdate: false,
        items: [],
        imageListAfter: [],
        imageListProcess: [],
        imageListBefore: [],
        selectedItems: [],
        isSelectedAll: false,
        selectedAction: 0,
        selectedDesa:"",
        jenisHewan:[],
        listKelompok: [],
        selectedKecamatan: "",
        selectedJenisHewanForm:{},
        page: 1,
        total: 0,
        form: {
          jenis_hewan_id:"",
          jenis_hewan: "",
          karkas:"",
          offal:"",
          karkas_offal: "",
          persentase_offal:"",
          persentase_lemak:"",
          konversi_karkas_ke_daging: "",
          unreg_pemotongan_ternak:"",
          pemotongan_terhadap_populasi:"",
          user_id:""
        },
        
        user: JSON.parse(localStorage.getItem("user")),
        params: {
          sorttype: "desc",
          sortby: "id",
          row: 500,
          page: 1,
          type: [0, 1],
          keyword: "",
          kecamatan:"",
          desa:"",
          jenisHewan:""
        },
        isSearching: false,
        searchOn: '',
        file: ''
      };
    },
    methods: {
      cancelImport(){
        this.file = ''
        this.createModalImport =  false
      },
      submitImport(){
        let formData = new FormData()
        formData.append('file', this.file)
        var loading = this.$loading.show();
        this.$store
          .dispatch('parameter_produksi/importFile', formData)
          .then((resp) => {
            this.$toast.success("Berhasil import data");
            this.createModalImport =  false
            loading.hide();
            this.file = ""
            this.getData()
            console.log(resp)
          })
          .catch(err => console.log(err))
      },
      getListKelompok() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("list_kelompok/getListKelompok", this.params)
          .then((resp) => {
            this.listKelompok = resp.data.data;
            // this.total = resp.data.total;
  
            // khusus untuk checkbox
            this.selectedItems = [];
            this.items.forEach(element => {
              if (this.isSelectedAll) {
                element.select = true;
                this.selectedItems.push(element.id);
              } else {
                element.select = false;
              }
            });
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      getJenisHewan() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("jenis_hewan/getJenisHewan", this.params)
          .then((resp) => {
            this.jenisHewan = resp.data.data;
  
            // khusus untuk checkbox
            this.selectedItems = [];
            this.items.forEach(element => {
              if (this.isSelectedAll) {
                element.select = true;
                this.selectedItems.push(element.id);
              } else {
                element.select = false;
              }
            });
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      search() {
        if (this.params.keyword != "") {
          this.isSearching = true;
          this.getData();
          this.searchOn = this.params.keyword;
          // this.params.keyword = '';
        } else {
          this.$toast.error("Inputan tidak boleh kosong !!");
        }
      },
  
      searchOff(){
        this.isSearching = false;
        this.params.keyword = '';
        this.getData();
      },
      submit() {
  
        this.form.user_id = JSON.parse(localStorage.getItem("user")).id;
        this.form.jenis_hewan_id = this.selectedJenisHewanForm.id
        this.form.jenis_hewan = this.selectedJenisHewanForm.nama
        this.form.karkas_offal = (+this.form.karkas + +this.form.offal).toString()

        var loading = this.$loading.show();
        this.$store
          .dispatch("parameter_produksi/addListParameter", this.form)
          .then(() => {
            this.$toast.success("Berhasil menambahkan data");
            loading.hide();
            this.createModal = false;
            this.form = {
              jenis_hewan_id:"",
              jenis_hewan: "",
              karkas:"",
              offal:"",
              karkas_offal: "",
              persentase_offal:"",
              persentase_lemak:"",
              konversi_karkas_ke_daging: "",
              unreg_pemotongan_ternak:"",
              pemotongan_terhadap_populasi:"",
              user_id:""
            }
            this.selectedJenisHewanForm = {}
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      },
      edit(item) {
        // console.log(item)
        this.form = item;
        this.isUpdate = true;
        this.createModal = true;
        this.selectedJenisHewanForm = {
          id : item.jenis_hewan_id,
          nama: item.jenis_hewan
        }

      },
      cancel() {
        this.form = {
          jenis_hewan_id:"",
          jenis_hewan: "",
          karkas:"",
          offal:"",
          karkas_offal: "",
          persentase_offal:"",
          persentase_lemak:"",
          konversi_karkas_ke_daging: "",
          unreg_pemotongan_ternak:"",
          pemotongan_terhadap_populasi:"",
          user_id:""
        }
        this.selectedJenisHewanForm = {}
        this.createModal = false;
      },
      update() {
        var loading = this.$loading.show();
        delete this.form.updated_at;
        console.log(this.form)
        this.form.jenis_hewan_id = this.selectedJenisHewanForm.id
        this.form.jenis_hewan = this.selectedJenisHewanForm.nama
        this.form.karkas_offal = (+this.form.karkas + +this.form.offal).toString()
        // this.form.nilai_kontrak = parseInt(this.form.nilai_kontrak);
        this.$store
          .dispatch("parameter_produksi/updateListParameter", {
            id: this.form.id,
            data: this.form,
          })
          .then(() => {
            this.$toast.success("Berhasil merubah data ");
            loading.hide();
            this.createModal = false;
            this.form = {
              jenis_hewan_id:"",
              jenis_hewan: "",
              karkas:"",
              offal:"",
              karkas_offal: "",
              persentase_offal:"",
              persentase_lemak:"",
              konversi_karkas_ke_daging: "",
              unreg_pemotongan_ternak:"",
              pemotongan_terhadap_populasi:"",
              user_id:""
            },
            this.selectedJenisHewanForm = {}
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      },
      hapus(item) {
        if (confirm("Data akan dihapus !!")) {
          this.$store
            .dispatch("parameter_produksi/deleteListParameter", item.id)
            .then(() => {
              this.$toast.success("Berhasil menghapus data ");
  
              this.form = {
                type: 0,
              };
              this.getData();
            })
            .catch((e) => {
              this.$toast.error(e);
              this.$loading.hide();
            });
        }
      },
      getData() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("parameter_produksi/getListParameter", this.params)
          .then((resp) => {
            // console.log(resp)
            this.items = resp.data.data;
            this.total = resp.data.total;
  
            // khusus untuk checkbox
            this.selectedItems = [];
            this.items.forEach(element => {
              if (this.isSelectedAll) {
                element.select = true;
                this.selectedItems.push(element.id);
              } else {
                element.select = false;
              }
            });
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      addModal() {
        this.isUpdate = false;
        this.createModal = true;
      },
      addModalImport() {
        console.log("awkwkkw");
        this.createModalImport = true;
      },
      pagination(page) {
        this.page = page;
        this.params.page = page;
        this.getData();
      },
      uploadAfter() {
        this.$refs.uploadFieldAfter.click();
      },
      selectFileAfter(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.imageListAfter.push(resp);
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      uploadProcess() {
        this.$refs.uploadFieldProcess.click();
      },
      importData($event) {
        console.log($event, "event");
        this.file = event.target.files[0];
        // var loading = this.$loading.show();
        
      },
      selectFileProcess(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.imageListProcess.push(resp);
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      uploadBefore() {
        this.$refs.uploadFieldBefore.click();
      },
      selectFileBefore(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadImage(this.file)
          .then((resp) => {
            this.imageListBefore.push(resp);
            loading.hide();
            alert("File berhasil diupload !!");
          })
          .catch((e) => {
            loading.hide();
            alert("Terjadi kesalahan !! | " + e);
          });
      },
      deleteImageBefore(state, index) {
        this.imageListBefore.splice(index, 1);
      },
      deleteImageProcess(state, index) {
        this.imageListProcess.splice(index, 1);
      },
      deleteImageAfter(state, index) {
        this.imageListAfter.splice(index, 1);
      },
      extractGallery(item) {
        let data = [];
        item.forEach(element => {
          data.push(element.image);
        });
        return data;
      },
      check(item) {
        if (item.select) {
          this.selectedItems.push(item.id);
        } else {
          const index = this.selectedItems.indexOf(item.id);
          this.selectedItems.splice(index, 1);
        }
      },
      checkAll() {
        this.getData();
      },
      changeActionSelected() {
        console.log(this.selectedAction)
        switch (Number(this.selectedAction)) {
          case 1:
            console.log('deleted')
            this.deleteSelected('delete');
            break;
          case 2:
            console.log('export excel')
            this.exportExcel('export_excel');
            break;
          case 3:
            console.log('export pdf')
            this.exportPDF();
            break;
        }
      },
      deleteSelected(action) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("list_parameter/selectedAction", 
          {
            action: action,
            data: this.selectedItems,
          })
          .then(() => {
            this.$toast.success("Item Selected Berhasil diproses");
            loading.hide();
            this.createModal = false;
            this.form = {
            };
            this.getData();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      openModalExcel() {
        this.exportModal = true;
        this.exportType = "Export Excel";
      },
      openModalPDF() {
        this.exportModal = true;
        this.exportType = "Export PDF";
      },
      exportExcel() {
        var loading = this.$loading.show();
        this.$store
         .dispatch("parameter_produksi/exportReport", {
            data: this.selectedItems,
          })
          .then((resp) => {
            console.log("export excel run")
            loading.hide();
            FileSaver.saveAs(
              resp.data,
              "Laporan_Harian_"
            );
            this.exportModal = false;
            this.exportDataParams = {};
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      },
      exportPDF() {
        this.$router.push({ name: "ExportParameterPdf", query: { data: this.selectedItems } });
      },
    },
    computed: {
      computedItems() {  
        return this.items.map((item, index) => {
          return {
            index: index+1 + '.',
            ...item,
            // nilai_kontrak: item.nilai_kontrak.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            // full_name: item.user != null ? item.user.full_name : "",
            // opd: item.role != null ? item.role.name : "",
            // created_at: item.created_at.slice(0, 10),
            dibuat_oleh: item.users.full_name,
            jenis_hewan: item.jenis_hewan.nama,
            tanggal_input: this.$moment(item.created_at).format("DD MMMM YYYY"),
            tanggal_update: this.$moment(item.updated_at).format("DD MMMM YYYY")
          };
        });
      },
    },
    mounted() {
      this.getData();
      this.getJenisHewan()
      this.getListKelompok()
    },
  
    created() {
      this.fields =
        this.user.role.name.toLowerCase() == "admin"
          ? data.fields
          : data.fields_no_action;
    },
  };
</script>

<style scoped>
.text-bold {
  font-weight: bold;
}
</style>